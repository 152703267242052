<!-- Localized -->
<template>
  <div
    class="timeline"
    :class="{
      'timeline--wrap': rowWrap,
    }"
    ref="container"
    :style="{ gap: `${contentGap}px` }"
  >
    <div
      v-for="(point, idx) in computedList"
      :key="point.id"
      class="timeline__item"
      :class="{
        'cursor-pointer': !readonly,
      }"
      ref="pointData"
      @click="selectPoint(point)"
    >
      <div
        class="point text-gray-600 text-xs"
        ref="pointContainer"
      >
        <div
          class="point__content-wrapper"
          :class="{
            'point__content-wrapper--passed text-success':  !checkCurrent(point),
            'point__content-wrapper--current text-primary': checkCurrent(point),
          }"
          :style="{
            width: pointWidth + 'px',
            height: pointWidth + 'px',
          }"
          ref="pointContent"
        >
          <font-awesome-icon
            v-if=" !checkCurrent(point)"
            class="point__content point__content--passed text-primary-content"
            icon="check"
          />
          <div
            v-else-if="checkCurrent(point)"
            class="point__content point__content--current text-primary"
          />
          <div
            v-else
            class="point__content point__content--default text-primary-content"
          >
            {{ idx + 1 }}
          </div>
          <div
            class="point__line text-success"
            :class="{
              'point__line--passed': checkPassed(point),
            }"
            ref="pointLine"
            :style="{
              marginInline: lineGap + 'px',
            }"
          />
        </div>
        <div
          v-if="point.status_name"
          class="point__label"
        >
          {{ point.status_name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "timeline",
  props: {
    /**
     * @typedef {Object} Point
     * @property {number} id
     * @property {string} [label]
     */
    /**
     * @type {Point[]}
     */
    list: {
      type: Array,
      required: true,
    },
    /**
     * @type {Point}
     */
    logs: {
      type: Array,
      default: null,
    },
    rowWrap: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    contentGap: {
      type: Number,
      default: 40,
    },
    lineGap: {
      type: Number,
      default: 8,
    },
    pointWidth: {
      type: Number,
      default: 20,
    },
    containerPadding: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      lastPoint: true,
    };
  },
  created() {
    window.addEventListener("resize", this.setTimelineSize);
  },
  mounted() {
    this.setTimelineSize();
  },
  destroyed() {
    window.removeEventListener("resize", this.setTimelineSize);
  },
  computed: {
    computedList() {
      return this.list.filter((el) => !el.hidden);
    },
  },
  methods: {
    // row wrap implementation (based on @shared/ChecksTimeline):
    setTimelineSize() {
      if (!this.$refs.pointData?.length) return;
      this.$refs.pointData?.forEach((point, i) => {
        this.$nextTick(() => {
          // check if point is the last:
          if (i <= this.$refs?.pointContent?.length-1) {
            const pointContainer = this.$refs?.pointContainer[i];
            const pointContent = this.$refs?.pointContent[i];
            const pointLine = this.$refs?.pointLine[i];

            const containerRect = this.$refs?.container?.getBoundingClientRect();
            const containerEnd = containerRect?.right;

            const pointContainerRect = pointContainer?.getBoundingClientRect();
            const pointContainerEnd = pointContainerRect?.right;

            const pointRect = pointContent?.getBoundingClientRect();
            const pointEnd = pointRect?.right;
            const isLastInLine = pointContainerEnd + this.containerPadding === containerEnd;
            const isLast = !this.$refs?.pointContent[i + 1];
            if (!isLastInLine && !isLast) {
              const nextPointContent = this.$refs?.pointContent[i + 1];
              const nextPointContentRect = nextPointContent?.getBoundingClientRect();
              const spaceWidth = nextPointContentRect.left - pointEnd;
              let lineWidth = spaceWidth - this.lineGap * 2;
              pointLine.style.width = lineWidth + "px";
            } else {
              pointLine.style.width = 0;
            }
          }
        })
      });
    },

    selectPoint(point) {
      if (!this.readonly)
        this.$emit('select', point)
    },
    checkCurrent(point) {
      
      return this.list.find((el) => el.current).status_id === point.status_id;
    },
    checkPassed(point) {
      if(!point){
        return
      }
      else{
        return true // We only show the passed and current data not show the next dat
      }
      
    }, 
        
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

* {
  box-sizing: border-box;
}
.timeline {
  display: flex;
  justify-content: flex-start;
  &--wrap {
    flex-wrap: wrap;
  }
  &__item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    .point {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      gap: toRem(4px);
      &__content-wrapper {
        position: relative;
        border-radius: 50%;
        background-color: currentColor;
        display: flex;
        align-items: center;
        justify-content: center;
        border: toRem(2px) solid currentColor;
        &--passed {
          padding: toRem(3px);
        }
        &--current {
          background: none;
          padding: toRem(2px);
        }
      }
      &__content {
        width: 10px;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: toRem(10px);
        &--current {
          border-radius: 50%;
          background-color: currentColor;
        }
      }
      &__icon {
        border-radius: 50%;
        background-color: currentColor;
        border: 5px solid currentColor;
      }
      &__line {
        width: 0;
        height: 3px;
        background-color: currentColor;
        position: absolute;
        top: 50%;
        left: 100%;
      }
      &__label {
        font-weight: normal;
        font-size: toRem(10px);
        line-height: toRem(16px);
        white-space: nowrap;
        min-width: toRem(80px);
        text-align: center;
      }
    }
  }
}
</style>
