<!-- Localized -->
<template>
    <div class="w-full flex flex-col px-4 pt-4 min-h-full max-h-full">
        <div class="flex items-center space-x-4 mb-4">
            <div class="text-md font-semibold text-gray-600 flex flex-col gap-4">
                {{ $t('case_creation.packages') }}
                <Input
                    class="w-70"
                    icon="search"
                    v-model="searchString"
                    :placeholder="$t('case_creation.search_package')"
                />
            </div>
        </div>

        <div class="grid w-full h-full gap-4 overflow-y-auto scroll-bar grid-cols-1 content-start 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-2 pr-2">
            <div
                v-if="loading"
                class="flex-grow w-full col-span-4 flex py-12"
            >
                <Loader :size="8" class="mx-auto my-5" />
            </div>
            <template v-else-if="filteredPackages?.length">
                <div
                    v-for="tenant_package in filteredPackages"
                    :key="tenant_package.id"
                    class="items-stretch"
                >
                    <PackageCard
                        class="h-full"
                        :tenant_package="tenant_package"
                        :progress="true"
                        @create="selectPackage"
                        :loading="!!packageLoaders[tenant_package.id]"
                    />
                </div>
            </template>
            <template v-else>
                <div class="col-span-4 wide:col-span-3">
                    <AlertInfo
                        type="info"
                        :message="$t('case_creation.no_client_packages')"
                    />
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import Input from "@shared/input";
import AlertInfo from "@shared/components/alert-box";
import Loader from "@shared/loader/index.vue";
import PackageCard from "@/components/package-card"; // component should present in current app

export default {
    components: {
        Input,
        AlertInfo,
        Loader,
        PackageCard,
    },
    props: {
        loading: Boolean,
        list: {
            type: Array,
            required: true,
        },
        packageLoaders: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            searchString: "",
        }
    },
    computed: {
        filteredPackages() {
            return this.list.filter((pkg) => pkg.package_name.toLowerCase().includes(this.searchString.toLowerCase()));
        },
    },
    methods: {
        selectPackage(payload) {
            this.$emit('select', payload);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>