<!-- Localized -->
<template>
  <component
    v-if="currentConfig?.component"
		:key="redirectionConfig.ack_id"
    class="max-h-full"
    :is="currentConfig.component"
    v-bind="currentConfig.props"
    v-on="currentConfig.listeners"
    @submit="submitScreen"
    @cancel="$emit('cancel')"
    @error="onError"
  />

  <!-- fallback -->
  <!-- <ModalContent
    v-else
		:show-footer="false"
  >
    <template #content>
      <Empty
        text="There is no data to display"
        classList="text-lg"
        :showBtn="false"
      />
    </template>
  </ModalContent> -->
</template>

<script>
/*
  * Use this component as an intermediate wrapper for the particular pop-up for the Entity Flow
*/

// import screen components
import {
  StateTransitionForm,
	StateTransitionInformation,
} from "./screens";
// TODO Form and Info pop-ups partly have the same functionality that may be moved here

// import ModalContent from "@shared/modal-content";
// import Empty from "@shared/empty";

import {
  redirectionDisplayVariants,
  screenList,
} from "@shared/RedirectionScreen/utils";

import {
  getSubConfigurationName,
} from "@shared/RedirectionScreen/utils/functions";

export default {
	components: {
		// ModalContent,
		// Empty,
	},
  props: {
    /**
     * @typedef {Object} SubConfig structure depends on its type
     * @property {String} [name]
     */
    /**
     * @typedef {Object} screenData
     * @property {String} configuration name
     * @property {SubConfig | String} sub_config sub configuration
     */
    /**
     * @type {screenData}
     */
  },

  inject: ["sharedCaseState"],

  computed: {
    configurationName() {
      return this.screenData?.configuration;
    },
    subConfiguration() {
      return this.screenData?.sub_configuration;
    },
    subConfigurationName() {
      return getSubConfigurationName(this.subConfiguration);
    },
		caseData() {
			return this.sharedCaseState();
		},
		redirectionConfig() {
			return this.caseData?.redirection_config?.[redirectionDisplayVariants.POPUP] || null;
		},
		screenData() {
			return this.redirectionConfig?.redirection_config || null;
		},
    screenConfig() {
      return {
        [screenList.STATE_TRANSITION_FORM]: {
          component: StateTransitionForm,
					props: {
						formId: this.subConfiguration?.form_id,
					},
        },
				// TODO
        [screenList.STATE_TRANSITION_INFORMATION]: {
          component: StateTransitionInformation,
					props: {
						label: this.subConfiguration?.label,
						fieldId: this.subConfiguration?.field_id,
					},
        },
      }
    },
    currentConfig() {
      const config = this.screenConfig[this.configurationName];
      const subConfig = config?.[this.subConfigurationName] ?? null; // return nested config if there are subconfigs
      return subConfig ?? config ?? null;
    },
  },
  methods: {
    submitScreen() {
      this.$emit("submit");
    },
    onError(error) {
      this.$emit("error", error);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>