export default {
  methods: {
    handleError(error, toast = true) {
      console.error('Error', error);
      if (toast) this.$toast.error(
        error?.response?.data?.detail[0]?.msg ||
          error?.response?.data?.detail ||
          error?.message ||
          this.$t("general.default_error")
      );
    },
  },
}