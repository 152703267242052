const formBuilderApiUrlList = {
	fetchForm: payload => `/case/form/${payload.case_id}/entity/${payload.entity_id}`,
	saveSection: payload => `/case/form/${payload.case_id}/entity/${payload.entity_id}`,
	submitForm: payload => `/case/${payload.case_id}/entity/${payload.entity_id}/form/${payload.form_id}/submit`,
	removeBlock: payload => `/case/form/${payload.case_id}/entity/${payload.entity_id}/multi-block`,
	removeField: payload => `/case/form/${payload.case_id}/entity/${payload.entity_id}/multi-field`,
}

export default class FormBuilderAPIUrlList {
	/**
	 * @typedef {Object} Config
	 * @property {string} case_id
	 * @property {string} entity_id
	 * @property {string} form_id
	 * 
	 * @param {Config} config 
	 * @param {Object} customAPI custom APIs
	 */
	constructor(config, customAPI = {}) {
		Object.entries(formBuilderApiUrlList).forEach(([key, value]) => {
				this[key] = customAPI?.[key]?.(config) ?? value(config);
			});
	}
}