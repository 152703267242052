<template>
    <div
        class="case-creation h-full flex flex-col max-h-full overflow-hidden"
        v-allow:auth="'case.create'"
    >
        <div
            v-if="loading"
            class="p-10 flex items-center justify-center"
        >
            <Loader />
        </div>
        <template v-else>
            <AlertInfo
                v-if="!selectedClientId && !selectedClient"
                class="text-sm mx-4 bg-blue-100 text-blue-900"
                :message="$t('general.case_create_message')"
            />
            <div class="flex overflow-hidden h-full">
                <ClientList
                    v-if="showClientList && !getIsClient"
                    :loading="loaders.clients"
                    :list="clients"
                    :selected="selectedClient"
                    @select="selectClient"
                />
                <CaseCreationFlow
                    v-if="showCaseCreation"
                    :loading="loaders.packages"
                    :tenant-id="getTenantId"
                    :client-id="selectedClient.id"
                    :preselected-package-id="computedSelectedPackageId"
                    :package-list="packages"
                    @cancel="exitCaseCreation"
                    @select:package="onPackageSelect"
                />
            </div>
        </template>
    </div>
</template>

<script>
import axios from "@/axios";
import CaseCreationFlow from "@shared/case-creation/CaseCreationFlow";
import Loader from "@shared/loader/index.vue";
import AlertInfo from "@/components/alert-box";
import ClientList from "@shared/case-creation/ClientList.vue";
import { mapGetters } from "vuex";
import { appList } from '@shared/components/form-builder/utils/index.js';

export default {
    name: "create-case",
    components: {
        CaseCreationFlow,
        Loader,
        AlertInfo,
        ClientList,
    },
    title: "Create Case",
    data() {
        return {
            appList,
            loading: false,
            selectedPackageId: null,
            selectedClientId: null, // manually selected client
            clients: [],
            packages: [],
            loaders: {
                clients: false,
                packages: false,
            },
        };
    },
    computed: {
        ...mapGetters([
            "getTenantId",
            "getIsClient",
            "getClientData",
        ]),
        selectedClient() {
            if (!this.getIsClient) {
               return this.computedSelectedClientId ? this.clients.find((el) => el.id === this.computedSelectedClientId) : null;
            } else {
                return this.getClientData;
            }
        },
        computedSelectedClientId() {
            return this.getClientData?.id
                || this.$route.query.client
                || this.selectedClientId;
        },
        computedSelectedPackageId() {
            return this.$route.query.packageid
                || this.selectedPackageId;
        },
        showCaseCreation() {
            return !!this.selectedClient;
        },
        showClientList() {
            return !(this.showCaseCreation && this.selectedPackageId);
        },
    },
    created() {
        const { packageid } = this.$route.query;
        this.selectedPackageId = packageid || null;
    },
    async mounted() {
        this.loading = true;
        if (!this.getIsClient)
            await this.fetchAllClients();        

        const { client } = this.$route.query;
        this.selectedClientId = client || null;
        this.loading = false;

        if (this.computedSelectedClientId) {
            await this.fetchPackages(this.computedSelectedClientId);
        }
    },
    methods: {
        async fetchAllClients() {
            try {
                this.loaders.clients = true;
                this.clients = [];
                const { data } = await axios(`tenant/${this.getTenantId}/client`);
                this.clients = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.clients = false;
        },
        async selectClient(client) {
            this.resetClient(client.id);
            await this.fetchPackages(this.computedSelectedClientId);
            this.$router.push({ query: { client: this.computedSelectedClientId } });
        },
        async fetchPackages(client_id) {
            try {
                this.packages = [];
                this.loaders.packages = true;
                const { data } = await axios(`screening-package/client/${client_id}`);
                this.packages = data;
            } catch (error) {
                console.log("error :>> ", error);
            }
            this.loaders.packages = false;
        },
        resetClient(payload = null) {
            this.packages = [];
            this.selectedClientId = payload;
        },
        exitCaseCreation() {
            if (!this.getIsClient) {
                this.$router.push({ query: { client: this.selectedClientId } });
                this.selectedPackageId = null;
            } else {
                this.$router.push({ name: "dashboard.view" });
            }
        },
        onPackageSelect(payload) {
            // Redirect with the query.packageid is musthave. Currently, is executed within CaseCreationFlow.vue
            this.selectedPackageId = payload;
        },
    },
};

</script>

<style lang="scss" scoped>
</style>
