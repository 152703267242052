<!-- Localized -->
<template>
    <UIContentBox
        class="collect-info__form border border-box-stroke"
        show-header
        header-classes="bg-primary-100"
        content-classes="flex flex-col gap-1"
    >
        <template #header v-if="headerConfig.showButton">
            <div class="flex justify-end align-center w-full h-full pr-4">
                <button
                    @click="$emit(headerConfig.event)"
                >
                    <component
                       v-if="headerConfig.component"
                       :is="headerConfig.component"
                    />
                </button>
            </div>
        </template>

        <FormulateInput
            v-model="formModel"
            :multiple="false"
            class="flex-1"
            :label="$t('collect_info_form.select_form')"
            :placeholder="$t('collect_info_form.select_form')"
            type="multi-select"
            variant="alt"
            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
            :options="options.form"
            :config="multiselectConfig"
            :allowEmpty="false"
            v-bind="config.props?.form"
        />
        <FormulateInput
            v-model="userFieldModel"
            :multiple="false"
            class="flex-1"
            :label="$t('collect_info_form.user')"
            :list-label="$t('collect_info_form.select_user')"
            :placeholder="$t('collect_info_form.select_user')"
            type="single-select-with-actions"
            :element-class="(context, classes) => ['flex-1 min-w-full'].concat(classes)"
            :options="selectUserOptions"
            :actions="selectUserActions"
            v-bind="config.props?.user"
            :searchable="canAddUser"
            :taggable="canAddUser" 
            :addTag="onAddUserOption"
        />
        <FormulateInput
            v-model="sequenceModel"
            :multiple="false"
            class="flex-1"
            :label="$t('collect_info_form.email')"
            :placeholder="$t('collect_info_form.select_email_sequence_template')"
            type="multi-select"
            variant="alt"
            :element-class="(context, classes) => ['flex-1 min-w-full text-base-content'].concat(classes)"
            :options="selectSequenceOptions"
            :config="multiselectConfig"
            showIcon
            searchable
            v-bind="config.props?.sequence"
        />
        
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </UIContentBox>
</template>

<script>
import WriteIcon from "@shared/assets/icons/roles/write.svg";
import ReadIcon from "@shared/assets/icons/roles/read.svg";
import TemplateIcon from "@shared/assets/icons/icon-text-temp.svg";
import SequenceIcon from "@shared/assets/icons/icon-group.svg";
import { validateEmail } from "@shared/plugins/functions.js";

export default {
    props: {
        /**
         * @typedef {'NONE' | 'READ' | 'WRITE'} Role
         */
        /**
         * @typedef {object} ModelValue
         * @property {string} form form id
         * @property {string} user email
         * @property {string} sequence sequence id
         * @property {Role} role
         */
        /**
         * @type {ModelValue}
         */
        value: {
            type: Object,
            required: true,
        },
        /**
         * @typedef {object} FieldProps
         * @property {object} form
         * @property {object} user
         * @property {object} sequence
         */
        /**
         * @typedef {object} FieldOptions
         * @property {array} form
         * @property {array} user
         * @property {array} sequence
         */
        /**
         * @typedef {object} Config
         * @property {FieldProps} props
         * @property {FieldOptions} options
         */
        /**
         * @type {Config}
         */
        config: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            multiselectConfig: {
                label: 'label',
                trackBy: 'value',
                multiple: false,
            },
            options: {}
        }
    },
    watch: {
        config: {
            immediate: true, 
            deep: true,
            handler(val) {
                this.options = val.options
            }
        }
    },
    computed: {
        canAddUser() {
           return !this.value?.id 
        }, 
        selectUserActions() {
            return [
                {
                    value: 'READ',
                    label: this.$t('collect_info_form.read'),
                    selectAll: false,
                    component: ReadIcon,
                },
                {
                    value: 'WRITE',
                    label: this.$t('collect_info_form.write'),
                    selectAll: true,
                    component: WriteIcon,
                },
            ]
        },
        selectUserOptions() {
            return this.options?.user
                ?.map((el) => ({
                    id: el,
                    name: el,
                })) || [];
        },
        selectSequenceOptions() {
            const iconConf = {
                'sequence': SequenceIcon,
                'template': TemplateIcon,
            }
            return this.options?.sequence
                ?.map((el) => ({
                    icon: iconConf[el.type],
                    label: el.name,
                    value: el.id,
                    ...el,
                })) || [];
        },
        headerConfig() {
            return this.config.header ?? {};
        },
        // find selected option
        formModel: {
            get() {
                return this.value?.form
                    ? this.options?.form?.find((el) => el.id === this.value?.form)
                    : null
            },
            set(val) {
                this.updateValue(val?.id, 'form');
            },
        },
        // used for both user email and role:
        userFieldModel: {
            get() {
                if (!this.value?.user) return null;
                const currentOption = this.selectUserOptions.find((el) => el.id === this.value?.user);
                const currectActions = this.formRolesValue(this.value?.role)
                return {
                    ...currentOption,
                    actions: currectActions,
                }
            },
            set(val) {
                const newEmail = val?.id || null;
                const newRole = this.parseSelectedRoles(val?.actions);

                // check to avoid cycled changes because everytime the new result object is created:
                const isChanged = !(newEmail === this.value?.user && newRole === this.value?.role);
                if (!isChanged) return;

                this.$emit('input', {
                    form: this.value.form,
                    sequence: this.value.sequence,
                    user: val?.id || null,
                    role: this.parseSelectedRoles(val?.actions),
                })
            },
        },
        // find selected option
        sequenceModel: {
            get() {
                return this.value?.sequence
                    ? this.selectSequenceOptions.find((el) => el.id === this.value?.sequence)
                    : null
            },
            set(val) {
                this.updateValue(val?.id, 'sequence');
            },
        },
    },
    methods: {
        onAddUserOption(val) {
            if (validateEmail(val)) {
                this.$emit('addCollectInfoUser', val);
            } else {
                this.$toast.error(this.$t('collect_info_form.invalid_email'));
            }
        }, 
        updateValue(payload, key) {
            this.$emit('input', {
                form: this.value.form,
                user: this.value.user,
                role: this.value.role,
                sequence: this.value.sequence,
                [key]: payload || null,
            })
        },
        parseSelectedRoles(selected = []) {
            if (!selected?.length) return 'NONE';
            if (selected.includes('WRITE')) return 'READ_WRITE';
            else if (selected.includes('READ')) return 'READ';
            return 'NONE';
        },
        /**
         * @param {'NONE' | 'READ' | 'WRITE'} payload role value
        */
        formRolesValue(payload = null) {
            if (!payload) return null;
            let result = [];
            this.selectUserActions
                .map((el) => el.value)
                .forEach((el) => {
                    if (payload.includes(el)) result.push(el);
                })
            return result;
        },
    },
}
</script>

<style lang="scss" scoped>
::v-deep {
    .multiselect {
        svg {
            color: black !important; // TODO the color must be fixed in global styles instead
        }
    }
}
</style>