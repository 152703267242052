var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"container",staticClass:"timeline",class:{
    'timeline--wrap': _vm.rowWrap,
  },style:({ gap: `${_vm.contentGap}px` })},_vm._l((_vm.computedList),function(point,idx){return _c('div',{key:point.id,ref:"pointData",refInFor:true,staticClass:"timeline__item",class:{
      'cursor-pointer': !_vm.readonly,
    },on:{"click":function($event){return _vm.selectPoint(point)}}},[_c('div',{ref:"pointContainer",refInFor:true,staticClass:"point text-gray-600 text-xs"},[_c('div',{ref:"pointContent",refInFor:true,staticClass:"point__content-wrapper",class:{
          'point__content-wrapper--passed text-success':  !_vm.checkCurrent(point),
          'point__content-wrapper--current text-primary': _vm.checkCurrent(point),
        },style:({
          width: _vm.pointWidth + 'px',
          height: _vm.pointWidth + 'px',
        })},[( !_vm.checkCurrent(point))?_c('font-awesome-icon',{staticClass:"point__content point__content--passed text-primary-content",attrs:{"icon":"check"}}):(_vm.checkCurrent(point))?_c('div',{staticClass:"point__content point__content--current text-primary"}):_c('div',{staticClass:"point__content point__content--default text-primary-content"},[_vm._v(" "+_vm._s(idx + 1)+" ")]),_c('div',{ref:"pointLine",refInFor:true,staticClass:"point__line text-success",class:{
            'point__line--passed': _vm.checkPassed(point),
          },style:({
            marginInline: _vm.lineGap + 'px',
          })})],1),(point.status_name)?_c('div',{staticClass:"point__label"},[_vm._v(" "+_vm._s(point.status_name)+" ")]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }