/* Use to manage API changes */

import axios from "@/axios";

/**
 * @typedef {Object} SectionData payload from the FormBuilder
 * @property {Object} data
 * 
 * @param {SectionData} data 
 * @returns {SectionData[]} formatted payload
 */
export const formatSections = (data) => {
	return { sections: [data.data] };
};

export const fetchEntityForm = async ({
	case_id,
	entity_id,
}) => {
	const { data } = await axios(
		`case/${case_id}/entity/${entity_id}/case-entity-creation-form-v2`
	);

	return data;
}

/**
 * @typedef {Object} SaveFormPayload
 * @property {String} case_id
 * @property {String} entity_id
 * @property {SectionData} data payload from the FormBuilder
 * 
 * @param {SaveFormPayload} payload 
 * @returns {Boolean} flag if form has been saved
 */
export const saveEntityFormSection = async ({
	case_id,
	entity_id,
	data,
}) => {
	let sectionSaved = false;
	await axios.post(
		`case/${case_id}/entity/${entity_id}/case-entity-creation-form`,
		formatSections(data)
	);
	sectionSaved = true;

	return sectionSaved;
}

export const submitEntityForm = async ({
	case_id,
	entity_id,
}) => {
	await axios.post(
		`case/${case_id}/entity/${entity_id}/case-entity-creation-form/submit`
	);
}