import { monitoringTools } from "@shared/utils/constants";
import { getEntitiesByKeyList } from "@shared/components/form-builder/utils";
import { unknownStatusList } from "./constants";

const extractFieldName = (input) => {
  // extract field name from the mask "<field_name>":
  if (typeof input === 'string') {
    const match = input.match(/^<(.+)>$/);
    return match ? match[1] : null;
  } else {
    return null;
  }
}

const prebuiltQueryParser = {
  parsers: {
    default: (prebuiltQuery, entityForm) => {
      if (!prebuiltQuery || typeof prebuiltQuery !== "object") return null;
      // prepare prebuilt query payload based on prebuiltQuery:
      return Object.entries(prebuiltQuery)
        .reduce((result, [key, value]) => {
          // recursively check nested properties:
          if (typeof value === "object" && value !== null && !Array.isArray(value)) {
            const parsedValue = prebuiltQueryParser.parsers.default(value, entityForm);
            if (parsedValue !== null) {
              result[key] = parsedValue;
            }
          } else {
            const fieldName = extractFieldName(value);
  
            // if there is a plain value add it to the payload:
            if (!fieldName) result[key] = value;
            else {
              // find the field in the entity's form:
              const fields = getEntitiesByKeyList(entityForm?.formData?.sections, { field_internal_name: fieldName });
              // add a value only if there is only one field with such a type:
              if (fields.length === 1) {
                const field = fields[0];
                if (field?.value) {
                  result[key] = field.value;
                }
              }
            }
          }

          return result;

        }, {})
    },
    [monitoringTools.SEARCH_ENGINE]: (prebuiltQuery, entityForm) => {
      const query = prebuiltQuery?.search_query;
      if (typeof query !== "string") return null;

      return query.replace(/<([^>]+)>/g, (_, fieldName) => {
        let fieldValue = "";
        const fields = getEntitiesByKeyList(entityForm?.formData?.sections, { field_internal_name: fieldName });
        if (fields.length === 1) {
          const field = fields[0];
          if (field?.value) {
            fieldValue = field.value;
          }
        }
        return fieldValue;
      });
    },
  },
  parse(query, entityForm, toolName) {
    const currentParser = this.parsers[toolName] || this.parsers.default;
    try {
      return currentParser(query, entityForm);
    } catch (err) {
      console.error("Parser error:", err);
      return null;
    }
  }
}

export const parsePrebuiltQuery = (query, entityForm, toolName) => {
  return prebuiltQueryParser.parse(query, entityForm, toolName);
}

export const checkUnknownStatus = (status) => unknownStatusList.includes(status);