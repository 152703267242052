<!-- Localized -->
<template>
    <div class="collect-info inline-flex flex-col w-full gap-5 max-h-full">
        <UIContentBox
            :class="{ 'gap-5': Boolean(activeModel) }"
            class="collect-info__form collect-info__form--grid w-full overflow-hidden"
        >
            <label class="flex gap-3 items-center text-lg cursor-pointer font-bold">
                <input
                    v-model="activeModel"
                    type="checkbox"
                    class="focus:ring-blue-500 text-blue-600 border-gray-300 rounded-md pointer-events-none w-6 h-6"
                    :disabled="canCollectFurtherInformationClient && canCollectFurtherInformationTenant"
                />
                {{ $t('case_creation.collect_further_information') }}
            </label>
            <div
                v-if="activeModel"
                class="min-h-full overflow-auto pr-1 scroll-bar"
            >
                <CollectInfoForm
                    v-model="detailsModel"
                    :config="{
                        options: detailsOptions,
                        props: detailsProps,
                    }"
                    @addCollectInfoUser="$emit('addCollectInfoUser', $event)"
                />
            </div>
        </UIContentBox>

        <UIContentBox class="flex justify-center items-start">
            <template v-if="!(isLast && allSaved)">
                <Button
                    v-if="!value?.active"
                    :loader="loading"
                    type="primary"
                    :text="handleButtonText('skip')"
                    @click="$emit('save')"
                />
                <Button
                    v-else
                    :loader="loading"
                    type="primary"
                    :text="handleButtonText('save')"
                    :disabled="!canSubmit"
                    @click="$emit('save')"
                />
            </template>

            <Button
                v-else
                :loader="loading"
                class="bg-success text-white"
                :disabled="!canSubmit"
                text="Submit"
                @click="$emit('submit')"
            />
        </UIContentBox>
    </div>
</template>

<script>
import CollectInfoForm from "@shared/components/CollectInfoForm/index.vue";
import Button from "@shared/components/button";
import { checkPermission, checkClientPermission } from "@shared/utils/functions"

export default {
    components: {
        Button,
        CollectInfoForm,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            required: true,
        },
        formOptions: {
            type: Array,
            default: () => [],
        },
        userOptions: {
            type: Array,
            default: () => [],
        },
        sequenceOptions: {
            type: Array,
            default: () => [],
        },
        isLast: {
            type: Boolean,
            default: false,
        },
        allSaved: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        canSubmit() {
            return !this.activeModel || (
                this.detailsModel?.form 
                && this.detailsModel?.user 
                && this.detailsModel?.sequence
            )
        },
        activeModel: {
            get() {
                return this.value?.active || false;
            },
            set(val) {
                this.$emit('input', {
                    active: val,
                    details: this.detailsModel,
                });
            },
        },
        detailsOptions() {
            return {
                form: this.formOptions,
                user: this.userOptions,
                sequence: this.sequenceOptions,
            }
        },
        detailsProps() {
            const defaultProps = {
                validation: 'required',
            }
            
            return {
                form: defaultProps,
                user: defaultProps,
                sequence: defaultProps,
            }
        },
        detailsModel: {
            get() {
                return this.value?.details || null;
            },
            set(val) {
                this.$emit('input', {
                    active: this.activeModel,
                    details: val,
                })
            },
        },
        canCollectFurtherInformationClient() {
            return !checkClientPermission('sequence.list') && !checkClientPermission('sequence.templateList')
        },
        canCollectFurtherInformationTenant() {
            return !checkPermission('sequence.list') && !checkPermission('sequence.templateList')
        }
    },
    methods: {
        checkPermission,
        checkClientPermission,
        handleButtonText(type) {
            const label = {
                skip: this.isLast ? this.$t('case_creation.skip') : this.$t('case_creation.skip_n_next'),
                save: this.isLast ? this.$t('case_creation.save') : this.$t('case_creation.save_n_next')
            }

            return label[type]
        },
    },
}
</script>

<style lang="scss" scoped>
.collect-info__form--grid {
    display: grid;
    grid-template-rows: auto 1fr;
}
</style>