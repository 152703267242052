<template>
	<div class="p-10 flex items-center justify-center w-full">
		<Loader />
	</div>
</template>

<script>
import Loader from "@shared/loader/index.vue";

export default {
	components: {
		Loader,
	},
	props: {
		loaderConfig: {
			type: Object,
			default: null,
		},
	}
};
</script>

<style lang="scss" scoped>
</style>