<!-- Localized -->
<template>
    <div
        class="entity flex items-center justify-center gap-6 h-10 pl-7 pr-4 py-2"
        :class="{
            'bg-transparent border-box py-2': !item.selected && !item.warning && !item.error,
            'bg-error': item.error && !item.selected,
            'opacity-50 pointer-events-none cursor-not-allowed': item.disabled,
            'cursor-pointer': item.selectable
        }"
        :style="{'border': item.selected ? `2px solid ${itemStatus?.status_colour}` : '' }"
        @click="onSelect"
    >
        <div class="entity__content flex items-center justify-center gap-2">
            <div
                v-if="item.type in entityTypesConfig"
                class="entity__content-underlay flex items-center justify-center"
                :style="{ 'border': `2px solid ${itemStatus?.status_colour}` }"
                :class="{ 'invisible': item.selected }"
            />

            <div
                v-if="item.type in entityTypesConfig"
                class="entity__icon-wrapper flex items-center justify-center relative rounded-full"
            >
                <span
                    class="flex items-center justify-center w-5 h-5 rounded-full"
                    :style="{ 'background-color': itemStatus?.status_colour || '' }"
                    :class="!itemStatus?.status_colour && {
                        'bg-primary': !item.selected,
                        'bg-white': item.selected
                    }"
                >
                <component
                    :is="entityTypesConfig?.[item.type]?.icon"
                    class="entity__icon"
                    :class="{ 'selected': !itemStatus?.status_colour && item.selected }"
                />
                </span>
            </div>
            <div class="entity__name w-48">
                <p
                    class="text-sm font-bold truncate"
                    :title="itemName"
                >
                    {{ itemName }}
                </p>
                <!-- <p
                    class="text-xs truncate"
                    :title="itemStatusLabel"
                >
                    {{ itemStatusLabel }}
                </p> -->
            </div>
        </div>

        <div
            v-if="showControls"
            class="entity__controls ml-auto flex gap-1 items-center"
        >
            <button
                v-if="editable"
                class="flex items-center justify-center text-primary"
                :title="$t('case_entities.edit')"
                v-tippy="{
                    content: $t('case_entities.edit'),
                }"
                @click.stop="$emit('edit')"
            >
                <EditIcon class="entity__btn-icon" :class="{ 'selected': item.selected }" />
            </button>
            <button
                v-if="!readonly && deletable"
                class="flex items-center justify-center text-primary"
                @click.stop="$emit('remove')"
                :title="$t('case_entities.delete')"
            >
                <TrashIcon class="entity__trash" :class="{ 'selected': item.selected }" />
            </button>

            <DropdownMenu
               v-if="item.statusList?.length"
               :value="itemStatus"
               value-attr="status_id"
               :options="item.statusList"
               placement="right"
               :min-option-width="178"
               :list-styles="`margin-top:15px`"
               :onSelect="(e) => handleEntityChangeStatus({ entity: item, status: e.option })"
            >
                <template v-slot:option="{ option }">
                    <span class="rounded-full w-5 border border-solid border-gray-300 flex-shrink-0 h-5" :style="{'background-color': option?.status_colour }"></span>
                    <div class="text-base truncate max-w-full text-sm" :title="option?.status_name" >
                        {{option?.status_name}}
                    </div>
                </template>
            </DropdownMenu>
        </div>

        <div
            v-else-if="item.checked"
            class="entity__check ml-auto flex items-center justify-center relative rounded-full bg-success"
        >
            <font-awesome-icon
                class="text-primary-content text-xs"
                icon="check"
            />
        </div>
    </div>
</template>

<script>
import { capitalize } from "lodash";
import { ENTITY_TYPE_NAMES } from "@shared/utils/constants.js";
import TrashIcon from "@shared/assets/icons/trash.svg";
const PersonIcon = () => import("@shared/assets/icons/entity/person.svg");
const CompanyIcon = () => import("@shared/assets/icons/entity/company.svg");
const EditIcon = () => import("@shared/assets/icons/edit.svg");
import DropdownMenu from "./DropdownMenu.vue";
export default {
    components: {
        TrashIcon,
        EditIcon,
        DropdownMenu
    },

    props: {
        /**
         * @typedef {Object} Item
         * @property {number} id
         * @property {string} type
         * @property {string} [name]
         * @property {boolean} [active]
         * @property {boolean} [checked]
         */
        /**
         * @type {Item}
         */
        item: {
            type: Object,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        selectable: {
            type: Boolean,
            default: true,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        deletable: {
            type: Boolean,
            default: false,
        },
        
        handleEntityChangeStatus: {
            type: Function,
            default: () => {},
        },
    },

    computed: {
        entityTypesConfig() {
            return {
                [ENTITY_TYPE_NAMES.PERSON]: {
                    id: ENTITY_TYPE_NAMES.PERSON,
                    icon: PersonIcon,
                },
                [ENTITY_TYPE_NAMES.COMPANY]: {
                    id: ENTITY_TYPE_NAMES.COMPANY,
                    icon: CompanyIcon,
                },
            }
        },

        itemName() {
            return this.item?.name?.split(' ').map(i => capitalize(i)).join(' ') || '---';
        },

        showControls() {
            return !this.readonly || this.editable;
        },

        itemStatus () {
            return this.item.statusList?.find(x=> !!x.current);
        },

        itemStatusLabel () {
            return this.itemStatus ? `(${this.itemStatus?.status_name})` : ''
        }
    },

    methods: {
        onSelect() {
            if (this.selectable) this.$emit("select");
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.entity {
    $this: &;

    position: relative;

    &:hover {
        #{$this}__content-underlay {
            background-color: var(--theme-color-primary-100);
            transition: background-color 0.15s cubic-bezier(0.4, 0, 0.2, 1);
        }
    }

    &__content-underlay {
        position: absolute;
        top: 6px;
        right: 0;
        bottom: 10px;
        left: 0;
        z-index: 0;
        border: 1px solid var(--theme-color-line);
        border-radius: 8px;
        height: toRem(28px);
    }

    &__icon-wrapper, &__check {
        position: relative;
        z-index: 1;
        overflow: hidden;
        width: toRem(22px);
        height: toRem(20px);
        flex-shrink: 0;
    }

    &__icon-underlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: .2;
    }

    &__name {
        position: relative;
        z-index: 1;
        max-width: toRem(80px);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &__controls {
        position: relative;
        z-index: 1;
    }

    &__icon {
        fill: var(--theme-color-white-text);

        &.selected {
            fill: var(--theme-color-main);
        }
    }

    &__btn-icon {
        fill: var(--theme-color-base-content);
        &:hover {
            fill: var(--theme-color-main);
        }

        &.selected {
            fill: var(--theme-color-white-text);
        }
    }

    &__trash {
        fill: var(--theme-color-main) ;

        &.selected {
            fill: var(--theme-color-white-text);
        }
    }
}
</style>
