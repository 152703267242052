import { getRouteParams } from "./functions";
// TODO move constants to /constants

// extend if main screen names are needed:
export const screenList = Object.freeze({
	DASHBOARD: "dashboard",
	CASE_SCREEN: "case screen",
	CHECK_OUTPUT: "check output",
	STATE_TRANSITION_FORM: "state transition form",
	STATE_TRANSITION_INFORMATION: "state transition information",
})

export const redirectionDisplayVariants = Object.freeze({
	SCREEN: "screen", // default
	POPUP: "pop-up",
	REDIRECT: "route-change",
})

// add other configured screens to display here
export const subScreenList = Object.freeze({
	// provide Boolean or Object value (config for the configuration screen variants)
	// [screenList.CASE_SCREEN]: {
	// 	INPUT_TAB: "input tab",
	// },
	[screenList.STATE_TRANSITION_FORM]: true,
	[screenList.STATE_TRANSITION_INFORMATION]: true,
	[screenList.CHECK_OUTPUT]: true,
	[screenList.DASHBOARD]: getRouteParams.dashboard,
})

export const redirectionDisplayConfig = Object.freeze({
	[screenList.CHECK_OUTPUT]: redirectionDisplayVariants.SCREEN,
	[screenList.STATE_TRANSITION_FORM]: redirectionDisplayVariants.POPUP,
	[screenList.STATE_TRANSITION_INFORMATION]: redirectionDisplayVariants.POPUP,
	[screenList.DASHBOARD]: redirectionDisplayVariants.REDIRECT,
})