import { render, staticRenderFns } from "./alert-box.html?vue&type=template&id=3babbc48&scoped=true&external"
import script from "./alert-box.js?vue&type=script&lang=js&external"
export * from "./alert-box.js?vue&type=script&lang=js&external"
import style0 from "./alert-box.scss?vue&type=style&index=0&id=3babbc48&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3babbc48",
  null
  
)

export default component.exports