<template>
  <div class="flex p-1 overflow-x-auto scroll-bar">
    <div
      v-if="isLoading"
      class="flex items-center justify-center w-full h-12"
    >
      <Loader/>
    </div>

    <div
      v-else
      class="flex gap-2 w-full"
    >
      <!-- Flex items -->
      <PackageCard
        v-for="item in packages"
        :key="item.package_id"
        :packageName="item.package_name"
        :buttonName="$t('actions.start')"
        :packageDetail="item"
        :createCaseLoading="createCaseLoading"
        @createCase="startCreatingCase"
      />
    </div>
  </div>
</template>

<script>
import axios from "@/axios"
import VueCookies from "vue-cookies";
import { mapGetters, mapActions } from "vuex";
import Loader from "@shared/loader";
import PackageCard from "@shared/dashboard-components/card/PackageCard";
import {checkPermission} from "@shared/utils/functions"

export default {
  components: {
    Loader,
    PackageCard,
  },

  data() {
    return {
      isLoading: false,
      createCaseLoading: false,
      packages: [],
    }
  },

  computed: {
    ...mapGetters(["getClientData"]),

    clientId() {
      return this.getClientData?.id;
    },

    isCaseCreatePermission() {
      return checkPermission('case.read');
    },
  },

  async mounted() {
    if (this.isCaseCreatePermission) {
      this.initPackageList();
    }
  },

  methods: {
    ...mapActions([
      "createNewCase",
    ]),

    async initPackageList() {
      try {
        await this.fetchPackages();
      } catch (err) {
        console.log("error :>> ", err);
        this.isLoading = false;
      }
    },
    async fetchPackages() {
      try {
        this.isLoading = true;
        this.packages = [];
        const { data } = await axios(`screening-package/client/${this.clientId}`);
        this.packages = data;
        this.isLoading = false;
      } catch (error) {
        console.log("error :>> ", error);
        this.$toast.error(
          error?.response?.data?.detail || this.$t('pages.dashboard.fetch_packages_error')
        );
        this.isLoading = false;
      }
    },
    async startCreatingCase(tenant_package) {
      this.createCaseLoading = true;
      if (tenant_package?.automated) {
        const payload = {
          client_id: this.clientId,
        };
        try {
          const url = `/user/saas-token`;
          const data = await axios.post(url, payload);

          VueCookies.set("saas-token", data.data.token);

          sessionStorage.removeItem("neo_case_id");
          sessionStorage.removeItem("id");
          sessionStorage.removeItem("case_name");

          sessionStorage.setItem("package_id", tenant_package.id);
          localStorage.setItem("package_name", tenant_package.package_name);
          sessionStorage.setItem("client_id", this.clientId);

          let casePayload = { package_id: tenant_package.id };
          await this.createNewCase(
            casePayload,
            payload.client_id
          );
          this.createCaseLoading = false;
        } catch (err) {
          this.createCaseLoading = false;
          console.error(err);
          this.$toast(err?.response?.data?.details || this.$t('general.default_error'));
        }
      } else {
        this.createCaseLoading = false;
        this.$router.push({
          name: "Create Case",
          query: { packageid: tenant_package.id },
        });
      }
      this.createCaseLoading = false;
    },
  }
};
</script>

<style lang="scss" scoped>
</style>