<!-- Localized -->
<template>
  <BaseScreen :loading="isLoading">
		<FileManager
			ref="fileManager"
			v-bind="props"
			@on-tab-change="onFileManagerTabChange($event)"
			@on-add-folder="onAddNewFolder($event)"
			@on-add-files="onAddFiles($event)"
			@on-delete-directory="onDeleteDirectory($event)"
			@on-file-open="onFileOpen($event)"
		/>

		<!-- pdf report modal  -->
		<pdf-view-modal
			:showFooter="true"
			:pdfUrl="pdfLink"
			:isLoading="isReportLoading"
			footerBtnText="Save to Output"
			@pdfviewsubmit="onSaveToOutput()"
		/>
	</BaseScreen>
</template>

<script>
// TODO check provided app

import axios from "@/axios";
import FileManager from "@shared/dashboard-components/fileManager";
import PdfViewModal from "@shared/components/pdf-view/pdf-view-modal";
import { appList } from "@shared/components/form-builder/utils/index.js";
import { checkPermission } from "@shared/utils/functions";
import BaseScreen from "@shared/RedirectionScreen/components/Screen/components/BaseScreen.vue";

export default {
	components: {
		BaseScreen,
		FileManager,
		PdfViewModal,
	},
	props: {
    caseId: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
	},
	data() {
		return {
			appList,
			isLoading: true,
			isReportLoading: false,
			initData: null,
			props: {
				tabs: [
					{ key: 1, name: "Input", queryKey: "input" },
					{ key: 2, name: "Internal", queryKey: "internal" },
				],
				selected: 1,
				profileTab: () => 3, // selected tab is constantly "Output"
				report: {
					loading: () => this.isReportLoading,
					generateReport: this.generateReport,
				},
				showTabs: true,
				directories: [],
				isLoading: false,
				directory_type: "input",
				readOnly: !checkPermission("case.actions_external"),
			},
			pdfLink: null,
			pdfReportName: null,
		}
	},

	async mounted() {
		// TODO fetch file manager data
		await this.init();
	},

	methods: {
		// TODO
		async init(id = null, type = "input", checktype = null) {
			// TODO check default argument values
			this.isLoading = true;
			// TODO fetch data
			this.props.directory_type = type;
			this.props.directories = [];
			this.props.isLoading = true;
			this.props.directories = await this.fetchFiles(
				id,
				type,
				checktype
			);

			this.isLoading = false;
		},

		async fetchFiles(id, type, checkType) {
			// const id = payload || this.$route?.query["caseid"];
			const payload = {
				case_entity_id: this.entityId,
				case_id: this.caseId,
				// check_id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
				directory_type: type,
			};
			if (id) {
				if (checkType === "referee") {
					payload.case_reference_id = id;
				} else {
					payload.check_id = id;
				}
				payload.directory_type = "input";
				// case_reference_id
				// this.$set(this.)
				this.$set(
					this.props,
					"selected",
					1
				);
				this.$set(this.props, "tabs", [
					{ key: 1, name: "Input", queryKey: "input" },
				]);
			} else {
				this.$set(this.props, "tabs", [
					{ key: 1, name: "Input", queryKey: "input" },
					{ key: 2, name: "Internal", queryKey: "internal" },
				]);
			}
			try {
				const { data } = await axios.post(
					`/case-file-manager/tenant/entity/get-file-data`,
					payload
				);
				return data?.data ?? [];
			} catch (error) {
				console.log(error, "[+] error while fetching files");
			}
		},

    async generateReport() {
      this.isReportLoading = true;
      try {
        let url = `report/generate/case?case_id=${this.caseId}`;
        let { data } = await axios.get(url);
        // to do : remove this dummy link
        this.pdfLink = data.report_url;
        this.pdfReportName = data.output_filename;
        this.$modal.show("pdf-view-modal");
        // window.open(this.pdfLink);
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.detail || this.$t('components.redirection_screen.error_create_report')
        );
      }
      this.isReportLoading = false;
    },

    onFileManagerTabChange(tab) {
      this.$refs.fileManager.resetState();
      this.$set(this.props, "selected", tab.key);
      this.init(null, tab.queryKey);
    },

    async onAddNewFolder(details = {}) {
      try {
        const payload = {
          case_id: this.caseId,
          directory_type: details.directory_type,
          resource_type: "directory",
          name: details.name,
          description: details.description,
          path: `${details.content.path}/${details.name}`,
          case_entity_id: this.entityId,
        };
        this.$set(this.rops, "isLoading", true);
        await this.uploadDirectoryContent(payload, 'Folder');
        // this.$toast.success("Folder added successfully")
        await this.init(
          null,
          details.directory_type
        );
      } catch (error) {
        console.log(error, "[+] error while adding directory");
      }
    },

    async onAddFiles({ files, content, directory_type }) {
      try {
        const formData = new FormData();
        formData.append("file", files[0].file);
        this.$set(this.props, "isLoading", true);
        const { data } = await axios.post(
          `/case-file-manager/case/${this.caseId}/entity/${this.entityId}/upload-file`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        const payload = {
          case_id: this.caseId,
          directory_type: directory_type,
          resource_type: "file",
          name: data?.data?.file_path ?? "",
          path: `${content.path}/${data?.data?.file_path ?? ""}`,
          case_entity_id: this.entityId,
        };
        // this.$toast.success("File Added successfully")
        await this.uploadDirectoryContent(payload, 'File');
      } catch (error) {
        this.$set(
          this.props,
          "isLoading",
          false
        );
        this.$toast.error(
          error.response.data.detail || this.$t('components.redirection_screen.error_add_file')
        );
      }
      this.$set(this.props, "isLoading", false);
      await this.init(null, directory_type);
    },

    async uploadDirectoryContent(payload = {}, type) {
      try {
        await axios.post("/case-file-manager", payload);
        this.$toast.success(this.$t('components.redirection_screen.type_added_successfully', { value: type }));
      } catch (error) {
        this.$toast.error(
          error?.response.data.detail
            ? error?.response.data.detail
            : "Some error occured while adding a folder"
        );
      }
    },

    async onDeleteDirectory(folder) {
      try {
        this.$set(this.props, "isLoading", true);
        const { data } = await axios.delete(`/case-file-manager/${folder.id}`);
        this.$toast.success(
          data?.message ? data?.message : this.$t('components.redirection_screen.success_delete')
        );
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.detail
            ? error?.response?.data?.detail
            : this.$t('components.redirection_screen.error_delete_folder')
        );
        console.log(error, "[+] error while deleting the directory");
      }
      await this.init(
        null,
        folder.directory_type
      );
    },

    async onFileOpen(details) {
      this.$set(this.props, "isLoading", true);
      try {
        const { data } = await axios.get(
          `/case-file-manager/get-case-entity-file-url/${details.file_id}`
        );
        window.open(data.url, "_blank");
      } catch (error) {
        console.log(error, "[+] failed to fetch file");
      }
      this.$set(this.props, "isLoading", false);
    },

    async onSaveToOutput() {
      try {
        await axios.post(`/case-file-manager/output`, {
          case_id: this.caseId,
          report_name: this.pdfReportName,
        });
        this.$modal.hide("pdf-view-modal");
        await this.init(null, "output");
        this.$toast.success(this.$t('components.redirection_screen.report_saved_to_output'));
      } catch (error) {
        this.$toast.error(
          error?.response?.data?.detail || this.$t('components.redirection_screen.error_save_report')
        );
      }
    },
	},
};
</script>

<style lang="scss" scoped>
// TODO
</style>