<!-- Localized -->
<template>
  <BaseScreen
    class="output-check-screen"
    :loaders="computedLoaders"
    :disabled-submit="disableSubmit"
    @submit="submitScreen"
  >
    <component
      v-if="currentConfig && isAllowedFeature"
      :is="currentConfig.component"
      class="output-check-screen__content relative w-full flex-1 flex flex-col h-full overflow-auto"
      ref="monitoring-tool"
      v-bind="currentConfig.props"
      v-on="currentConfig.listeners"
    />

    <div
      v-else
      v-allow:license="monitoringToolLicenses[currentConfig.id]"
    ></div>

    <template #footer:before>
      <label class="flex gap-3 items-center cursor-pointer">
        <input
          type="checkbox"
          class="ml-auto h-5 w-5 text-blue-600 pl-2 rounded"
          v-model="noMatch"
          :disabled="hasMatches"
        />
        <span class="text-base-content-200">
          {{ $t('components.redirection_screen.no_match_found') }}
        </span>
      </label>
    </template>
	</BaseScreen>
</template>

<script>
// TODO handle record status change
// TODO implement fallback for non-configured check
// add `v-allow:license="monitoringToolLicences[currentConfig.id]"` to the component props if licences should be checked

import axios from "@/axios";
import { mapGetters, mapActions } from "vuex";
import BaseScreen from "@shared/RedirectionScreen/components/Screen/components/BaseScreen.vue";
const LexisBridgerComponent = () => import("@shared/tools/lexis-bridger");
const ORBISComponent = () => import("@shared/tools/orbis");
const CorporateRecordsComponent = () => import("@shared/tools/company-identifier");
const SearchEngineComponent = () => import("@shared/tools/search-engine");
import {
  getrelationAttributes
} from "@shared/light-ray/services";

import {
  CORPORATE_RECORDS_CHECK_ID,
  LEXIS_BRIDGER_CHECK_ID,
  DNB_CHECK_ID,
  ORBIS_CHECK_ID,
  SEARCH_ENGINE_CHECK_ID,
  monitoringTools,
  monitoringToolLicenses,
  appList,
} from "@shared/utils/constants";
import {
  getMonitoringEntities,
} from "@shared/utils/functions.js";
import { parsePrebuiltQuery, checkUnknownStatus } from "./utils";

export default {
	components: {
		BaseScreen,
	},
	props: {
    checkId: {
      type: String,
      required: true,
    },
    checkName: {
      type: String,
      required: true,
    },
    integratedSourceId: {
      type: String,
      required: true,
    },
    app: {
      type: String,
      deafult: appList.CREATE,
    },
    entityChecks: {
      type: Array,
      default: () => [],
    },
    loaders: {
      type: Object,
      default: () => ({}),
    },
    prebuiltQuery: {
      type: Object,
      default: null,
    }
	},

  inject: ["sharedCaseState"],

	data() {
		return {
			isLoading: true,
      noMatch: false,
		}
	},

	async mounted() {
		await this.init();
    const checkData = this.entityChecks?.find((el) => el.check_id === this.checkId || el.integration_source_id  === this.integratedSourceId);
    this.$store.commit("SET_CHECK_DESCRIPTION", checkData?.description || "");
    this.$store.commit("SET_SELECTED_CHECK_LABEL", (checkData?.check_label || checkData?.check_name || ""));
	},

  computed: {
    ...mapGetters([
      "getDomainCompanyDetailsSearchList",
      "getAdverseFilteredResults",
      "getSearchEngineResultList",
      "getUserFeatures"
    ]),
  
    isAllowedFeature() {
      const featureLicenses = monitoringToolLicenses[this.currentConfig.id];
      // allow if there is no configured license:
      if (!featureLicenses) return true;

      return monitoringToolLicenses[this.currentConfig.id].some(res => this.getUserFeatures.includes(res));
    },
    
    hasMatches() {
      return !!this.currentConfig.matches.length;
    },
    caseData() {
      return this.sharedCaseState();
    },
    caseId() {
      return this.caseData.case_id;
    },
    selectedEntityId() {
      return this.caseData.selected_entity_id;
    },
    selectedEntity() {
      return this.caseData.selected_entity;
    },
    selectedEntityForm() {
      return this.caseData.selected_entity?.form;
    },
    caseEntities() {
      return this.caseData.case_entities;
    },
    caseEntitiesData() {
      return this.caseData.case_entities_data;
    },
    clientId() {
      return this.caseData.client_id;
    },
    disableSubmit() {
      return !(this.hasMatches || this.noMatch);
    },

    monitoringEntities() {
      return getMonitoringEntities(this.caseEntities, this.caseEntitiesData);
    },

    toolsConfig() {
      return {
        [monitoringTools.CORPORATE_RECORDS]: {
          id: monitoringTools.CORPORATE_RECORDS,
          component: CorporateRecordsComponent,
          props: {
            title: this.checkName,
            autorun: this.recordsAutorunConfig(monitoringTools.CORPORATE_RECORDS),
            mainEntity: this.monitoringEntities,
            checkId: this.checkId,
            entityId: this.selectedEntityId,
            listDisabled: this.noMatch,
            prebuiltQuery: this.getParsedPrebuiltQuery(),
          },
          matches: this.getDomainCompanyDetailsSearchList.filter(x => !!x?.card_status && !checkUnknownStatus(x.card_status)),
        },
        [monitoringTools.DNB]: {
          id: monitoringTools.DNB,
          component: CorporateRecordsComponent,
          props: {
            title: this.checkName,
            autorun: this.recordsAutorunConfig(monitoringTools.DNB),
            mainEntity: this.monitoringEntities,
            checkId: this.checkId,
            entityId: this.selectedEntityId,
            listDisabled: this.noMatch,
            prebuiltQuery: this.getParsedPrebuiltQuery(),
          },
          matches: this.getDomainCompanyDetailsSearchList.filter(x => !!x?.card_status && !checkUnknownStatus(x.card_status)),
        },
        [monitoringTools.ORBIS]: {
          id: monitoringTools.ORBIS,
          component: ORBISComponent,
          props: {
            title: this.checkName,
            autorun: this.recordsAutorunConfig(monitoringTools.ORBIS),
            mainEntity: this.monitoringEntities,
            checkId: this.checkId,
            entityId: this.selectedEntityId,
            listDisabled: this.noMatch,
            prebuiltQuery: this.getParsedPrebuiltQuery(),
          },
          matches: this.getDomainCompanyDetailsSearchList.filter(x => !!x?.card_status && !checkUnknownStatus(x.card_status)),
        },
        [monitoringTools.ORBIS]: {
          id: monitoringTools.ORBIS,
          component: ORBISComponent,
          props: {
            title: this.checkName,
            autorun: this.recordsAutorunConfig(monitoringTools.ORBIS),
            mainEntity: this.monitoringEntities,
            checkId: this.checkId,
            entityId: this.selectedEntityId,
            listDisabled: this.noMatch,
            prebuiltQuery: this.parsedPrebuiltQuery,
          },
          matches: this.getDomainCompanyDetailsSearchList.filter(x => !!x?.card_status && !checkUnknownStatus(x.card_status)),
        },
        [monitoringTools.LEXIS_BRIDGER]: {
          id: monitoringTools.LEXIS_BRIDGER,
          component: LexisBridgerComponent,
          props: {
            title: this.checkName,
            autorun: this.recordsAutorunConfig(monitoringTools.LEXIS_BRIDGER),
            mainEntity: this.monitoringEntities,
            checkId: this.checkId,
            entityId: this.selectedEntityId,
            listDisabled: this.noMatch,
            prebuiltQuery: this.getParsedPrebuiltQuery(),
          },
          matches: this.getAdverseFilteredResults.filter(x => !!x?.card_status && !checkUnknownStatus(x.card_status)),
        },
        [monitoringTools.SEARCH_ENGINE]: {
          id: monitoringTools.SEARCH_ENGINE,
          component: SearchEngineComponent,
          props: {
            title: this.checkName,
            // Search Engin already has another autorun functionality so we should provide different props
            autorunConfig: {
              ...this.recordsAutorunConfig(monitoringTools.SEARCH_ENGINE),
              prebuiltQuery: this.getParsedPrebuiltQuery(monitoringTools.SEARCH_ENGINE),
            },
            listDisabled: this.noMatch,
          },
          matches: this.getSearchEngineResultList.filter(x => !!x?.status && !checkUnknownStatus(x.status)),
        },
      }
    },

    currentConfig() {
      const integratedSourceConfig = {
        [CORPORATE_RECORDS_CHECK_ID]: monitoringTools.CORPORATE_RECORDS,
        [LEXIS_BRIDGER_CHECK_ID]: monitoringTools.LEXIS_BRIDGER,
        [DNB_CHECK_ID]: monitoringTools.DNB,
        [SEARCH_ENGINE_CHECK_ID]: monitoringTools.SEARCH_ENGINE,
        [ORBIS_CHECK_ID]: monitoringTools.ORBIS,
      }

      const currentTool = integratedSourceConfig[this.integratedSourceId] ?? null;

      return currentTool ? this.toolsConfig[currentTool] : null;
    },

    checkData() {
      return this.entityChecks?.find((el) => el.check_id === this.checkId || el.integration_source_id  === this.integratedSourceId) ?? null;
    },

    monitoringToolLicenses() {
      return monitoringToolLicenses;
    },

    computedLoaders() {
      return {
        ...this.loaders,
        content: this.isLoading, // use local loading state 
      }
    },
  },

  watch: {
    hasMatches(val) {
      if (val && this.noMatch) {
        this.noMatch = false;
      }
    }
  },

	methods: {
    ...mapActions({
      getAllFeaturesList: "getAllFeaturesList",
      fetchCasePersonalData: "fetchCasePersonalData"
    }),

    getParsedPrebuiltQuery(toolName) {
      if (!this.prebuiltQuery) return null;

      return parsePrebuiltQuery(this.prebuiltQuery, this.selectedEntityForm, toolName);
    },

    recordsAutorunConfig(type) {
      if (type === monitoringTools.CORPORATE_RECORDS) {
        return {
          headers: {
            "x-tool-name": "company-name",
            "x-entity-id": this.selectedEntityId,
          }
        }
      }
      if (type === monitoringTools.LEXIS_BRIDGER) {
        return {
          headers: {
            "x-tool-name": "lexis-bridger-tool",
            "x-entity-id": this.selectedEntityId,
          }
        }
      }
      if (type === monitoringTools.DNB) {
        return {
          headers: {
            "x-tool-name": "company-name",
            "x-entity-id": this.selectedEntityId,
          }
        }
      }
      if (type === monitoringTools.ORBIS) {
        return {
          headers: {
            "x-tool-name": "orbis-tool",
            "x-entity-id": this.selectedEntityId,
          }
        }
      }
    },

		async init() {
      await this.fetchCasePersonalData(this.clientId);
      const relationsAttributesData = await getrelationAttributes();
      // const socialPlatformIcons = await getSocialPlatforms();
      // const entitiesIdentifiers = await fetchEntityIdentifiers(this.getNeoCaseId);
  
      this.$store.commit(
        "SET_ENTITIES_RELATIONS_ATTRIBUTES_DATA",
        relationsAttributesData?.data?.data?.data ?? {}
      );
      const initMethods = {
        // initial data for Corporate Records
        [monitoringTools.CORPORATE_RECORDS]: this.getAllFeaturesList,
        [monitoringTools.LEXIS_BRIDGER]: this.getAllFeaturesList,
        [monitoringTools.DNB]: this.getAllFeaturesList,
        [monitoringTools.ORBIS]: this.getAllFeaturesList,
      }

      this.isLoading = true;
      await initMethods[this.currentConfig.id]?.();
      this.isLoading = false;
		},

    async submitCheck() {
      if (!this.checkData) return;

      const url = `case/${this.caseId}/entity/${this.selectedEntityId}/check/${this.checkData.id}`;

      await axios.get(
        url,
        { params: { no_records_found: this.noMatch } }
      );
    },

    async submitScreen() {
      const defaultSubmitMethod = this.submitCheck;

      // in case we need specific API call configure it here:
      const submitMethods = {
        // [monitoringTools.CORPORATE_RECORDS]:  this.submitCheck, // example
      }
      const currentSubmitMethod = submitMethods[this.currentConfig.id] || defaultSubmitMethod;

      try {
        await currentSubmitMethod?.();
        this.$emit('submit');
      } catch (error) {
        this.$emit('error', error);
      }
    },
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.output-check-screen {
  &__content {
    min-height: toRem(200px);
  }
}
</style>