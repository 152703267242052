<!-- Localized -->
<template>
  <ModalContent
    ref="popup"
    name="state-transition-info"
    width="30%"
    :min-width="440"
    :max-width="800"
    :show-header="false"
    :show-footer="false"
    :click-to-close="false"
    v-bind="$attrs"
    @submitModal="submitModal"
  >
    <template #content>
      <div class="popup-content">
        <p v-if="label" class="popup-content__label flex flex-col gap-4">
          {{ label }}
        </p>

        <Empty
          v-else
          :text="$t('components.redirection_screen.there_is_no_data_to_display')"
          classList="text-lg"
          :showBtn="false"
        />

        <Button
          class="mt-auto ml-auto"
          type="primary"
          :loader="isLoading"
          @click="submitModal"
          @keyup.enter="submitModal"
        >
        {{ $t('general.ok') }}
        </Button>
      </div>
    </template>
  </ModalContent>
</template>

<script>
import axios from "@/axios";
import handleError from "@shared/mixins/handleError.js";

import ModalContent from "@shared/modal-content";
import Empty from "@shared/empty";
import Button from "@shared/components/button";

export default {
  components: {
    ModalContent,
    Empty,
    Button,
  },

  mixins: [handleError],

  props: {
    label: {
      type: String,
      required: true,
    },
    fieldId: {
      type: String,
      required: true,
    },
  },

  inject: ["sharedCaseState", "currentApp"],

  data() {
    return {
      isLoading: false,
    };
  },

  async mounted() {
    this.$refs.popup.showModal();
  },

  computed: {
    caseData() {
      return this.sharedCaseState();
    },
    isCaseSubmitted() {
      return this.caseData.is_case_submitted;
    },
    currentWorkflowObjectId() {
      return this.caseData.current_workflow_object_id;
    },
  },

  methods: {
    async submitModal() {
      this.isLoading = true;
      try {
        const url = "case/state-transition-information-acknowledged";
        const payload = {
          field_id: this.fieldId,
        }
        // for the case (after case has been submitted), we send a specific `case_id` key:
        const objectKey = this.isCaseSubmitted ? "case_id" : "case_entity_id";
        payload[objectKey] = this.currentWorkflowObjectId;

        await axios.post(url, payload);
        this.$emit("submit");
      } catch (error) {
        this.handleError(error);
      }
      this.isLoading = false;

      this.$refs.popup.hideModal();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>