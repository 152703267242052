<!-- Localized -->
<template>
  <component
    v-if="currentConfig?.component && !currentConfig?.prohibited"
    class="max-h-full"
    :is="currentConfig.component"
    :key="currentConfig.key"
    :case-id="caseId"
    :entity-id="entityId"
    :case-entities="caseEntities"
    :case-entities-data="caseEntitiesData"
    :app="app"
    :loaders="loaders"
    v-bind="currentConfig.props"
    v-on="currentConfig.listeners"
    @submit="submitScreen"
    @error="onError"
  />

  <!-- fallback -->
  <BaseScreen
    v-else
    :loaders="computedLoaders"
    @submit="submitScreen"
  >
    <UIContentBox>
      <Empty
        :text="$t('components.redirection_screen.there_is_no_data_to_display')"
        classList="text-lg"
        :showBtn="false"
      />
    </UIContentBox>
  </BaseScreen>
</template>

<script>
// TODO: use this component on the Case page
// Use events to call methods needed specifically on the Case page

/*
  * Use this component as an intermediate wrapper for the particular screen for the Entity Flow
  * Use this component to set common logic: manage loaders, provide props, fallback screens, step controls, etc.
  * Call specific init methods within screen components
*/

// TODO:
/*
  - Configure timeline logic for every screen.
  - Configure multiple steps (screens) if needed. Example: Corporate Records -> Adverse Check (Lexis Bridger)
*/


import Empty from "@shared/empty";
import BaseScreen from "./components/BaseScreen.vue";

import { checkPermission } from "@shared/utils/functions";
import { PermissionError } from "@shared/utils/permissions";

// import screen components
import {
  // CaseInput,
  // CaseOutput,
  CheckOutput,
  Dashboard,
} from "./screens";

import {
  screenList,
  // subScreenList,
} from "@shared/RedirectionScreen/utils";

import {
  getSubConfigurationName,
} from "@shared/RedirectionScreen/utils/functions";

import { appList } from "@shared/components/form-builder/utils/index.js";

export default {
  props: {
    /**
     * @typedef {Object} SubConfig structure depends on its type
     * @property {String} [name]
     */
    /**
     * @typedef {Object} screenData
     * @property {String} configuration name
     * @property {SubConfig | String} sub_config sub configuration
     */
    /**
     * @type {screenData}
     */
    screenData: {
      type: Object,
      required: true,
    },
    caseId: {
      type: String,
      required: true,
    },
    entityId: {
      type: String,
      required: true,
    },
    caseEntities: {
      type: Array,
      default: () => [],
    },
    caseEntitiesData: {
      type: Object,
      required: true,
    },
    app: {
      type: String,
      deafult: appList.CREATE,
    },
    entityChecks: {
      type: Array,
      default: () => [],
    },
    loaders: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BaseScreen,
    Empty,
  },
  data() {
    return {}
  },
  computed: {
    configurationName() {
      return this.screenData.configuration;
    },
    subConfiguration() {
      return this.screenData.sub_configuration;
    },
    subConfigurationName() {
      return getSubConfigurationName(this.subConfiguration);
    },
    screenConfig() {
      return {
        // [screenList.CASE_SCREEN]: {
        //   [subScreenList.INPUT_TAB]: {
        //     component: CaseInput,
        //     props: {
        //       app: this.app,
        //     },
        //     listeners: {
        //       "update:entities": this.updateEntities,
        //     },
        //   },
        // },
        [screenList.CHECK_OUTPUT]: {
          component: CheckOutput,
          props: {
            checkId: this.subConfiguration.check_id,
            checkName: this.subConfiguration.check_name,
            integratedSourceId: this.subConfiguration.integration_source_id,
            entityChecks: this.entityChecks,
            prebuiltQuery: this.subConfiguration.query,
          },
          key: this.subConfiguration.integration_source_id,
          listeners: {},
          permission: "case.run_check",
          prohibited: !checkPermission("case.run_check"),
        },
        [screenList.DASHBOARD]: {
          component: Dashboard,
          props: {
            dashboardId: this.subConfiguration.dashboard_id,
            dashboardName: this.subConfiguration.name,
            class: "h-full",
          },
          key: this.subConfiguration.dashboard_id,
          listeners: {},
        },
      }
    },
    currentConfig() {
      const config = this.screenConfig[this.configurationName];
      const subConfig = config?.[this.subConfigurationName] ?? null; // return nested config if there are subconfigs
      return subConfig ?? config ?? null;
    },

    computedLoaders() {
      const { content, ...rest } = this.loaders;
      return {
        ...rest,
        content: content || this.currentConfig?.prohibited,
      }
    },
  },

  created() {
    if (this.currentConfig?.prohibited)
      this.onError(new PermissionError(this.currentConfig.permission));
  },

  methods: {
    submitScreen() {
      this.$emit("submit");
    },
    onError(error) {
      this.$emit("error", error);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>