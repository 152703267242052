import {
  DEFAULT_DASHBOARD_NAME,
  appList,
} from "@shared/utils/constants";
import {
  redirectionDisplayConfig,
  redirectionDisplayVariants,
  subScreenList,
} from "@shared/RedirectionScreen/utils";

const routeNameConfig = {
  default : {
    [DEFAULT_DASHBOARD_NAME]: "Dashboard - Page 2",
    default: "Bi-Dashboard-View",
  },
  [appList.CLIENT] : {
    [DEFAULT_DASHBOARD_NAME]: "Case Dashboard",
    default: "Bi-Dashboard-View",
  },
}

/**
 * 
 * @param {Object} config sub_configuration
 * @returns route redirection object
 */
const getDashboardRedirectionParams = (config = {}, app = appList.TENANT) => {
  const { dashboard_id, name } = config;
  if (!(dashboard_id && name)) throw new Error("Required params missed");

  const appRoutes = routeNameConfig[app] ?? routeNameConfig.default;
  const routeName = appRoutes[name] ?? appRoutes.default; 

  return {
    name: routeName,
    params: {
      id: dashboard_id,
    }
  }
}

export const getRouteParams = {
  dashboard: getDashboardRedirectionParams,
}

/**
 * @typedef {Object} RedirectionConfig
 * @property {String} ack_id
 * @property {RedirectionConfigDetails} redirection_config
 * 
 * @typedef {Object} RedirectionConfigDetails
 * @property {String} configuration
 * 
 * @param {RedirectionConfig} config 
 * @returns redirection config with display type
 */
export const getRedirectionDisplay = (config) => redirectionDisplayConfig[config?.redirection_config?.configuration] ?? redirectionDisplayVariants.SCREEN;


export const getSubConfigurationName = (subConfiguration) => {
	return subConfiguration
		? (typeof subConfiguration === "string" ? subConfiguration : subConfiguration.name) // may be either an object or a string
		: null;
}

export const getCurrentScreenSettings = (redirectionConfig) => {
  const configurationName = redirectionConfig?.configuration;

  const subConfiguration = redirectionConfig?.sub_configuration;
  const subConfigurationName = getSubConfigurationName(subConfiguration);

  const screenSettings = subScreenList[configurationName] ?? null;
  const subScreenSettings = typeof screenSettings?.[subConfigurationName] === "object"
    ? (screenSettings?.[subConfigurationName] ?? null)
    : null
  const currentScreenSettings = subScreenSettings || screenSettings;
  return currentScreenSettings;
}