<!-- Localized -->
<template>
  <BaseScreen
    class="redirection-screen"
    :loaders="loaders"
    @submit="submitScreen"
  >
    <UIContentBox class="h-full overflow-auto scroll-bar">
      <component
        v-if="dashboardId"
        :is="currentDashboard.component"
        :dashboard-id="dashboardId"
        :client-id="clientId"
        v-bind="currentDashboard.props"
      />
    </UIContentBox>
	</BaseScreen>
</template>

<script>
import BaseScreen from "@shared/RedirectionScreen/components/Screen/components/BaseScreen.vue";
import {
  BiDashboardView,
  DefaultDashboardView,
} from "@shared/dashboard-components/dashboard-view";

const dashboardNameMap = {
  "Default Dashboard": "default",
  other: "other", // all except default
}

export default {
	components: {
		BaseScreen,
	},
	props: {
    loaders: {
      type: Object,
      default: () => ({}),
    },
    dashboardId: {
      type: String,
      required: true,
    },
    dashboardName: {
      type: String,
      default: "",
    },
	},

  inject: ["sharedCaseState"],

	data() {
		return {}
	},

  computed: {
    caseData() {
      return this.sharedCaseState();
    },
    clientId() {
      return this.caseData.client_id;
    },
    dashboardConfig() {
      return {
        [dashboardNameMap.other]: {
          component: BiDashboardView,
        },
        [dashboardNameMap["Default Dashboard"]]: {
          component: DefaultDashboardView,
          props: {
            allowDashboardSelect: false,
            isDefaultDashboard: true,
          },
        },
      }
    },
    currentDashboard() {
      const dashboard = dashboardNameMap[this.dashboardName] ?? dashboardNameMap["other"];
      return this.dashboardConfig[dashboard];
    },
  },

	methods: {
    async submitScreen() {
      this.$emit('submit');
    },
	},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/functions.scss";

.redirection-screen {
  &__content {
    min-height: toRem(200px);
  }
}
</style>