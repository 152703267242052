<!-- Localized -->
<template>
  <div
    class="flex shrink-0 gap-1 bg-white p-2 rounded-xl items-center package-card-container"
  >
    <div class="w-full flex gap-4 items-center  ">
      <div class="flex package-name-container items-center">
        <span class="text-main package-name truncate" :title="packageName">{{
          packageName
        }}</span>
      </div>
      <div class="flex  justify-between items-center gap-4 cursor-pointer">
        <div class="flex w-full justify-center" :title="getDescription" v-if="getDescription">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            :title="packageName"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.08 8C14.08 11.3579 11.3579 14.08 8 14.08C4.64211 14.08 1.92 11.3579 1.92 8C1.92 4.64211 4.64211 1.92 8 1.92C11.3579 1.92 14.08 4.64211 14.08 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6.9966 4.29878C6.9966 3.77434 7.42686 3.47852 7.97817 3.47852C8.5026 3.47852 8.9463 3.77438 8.9463 4.29878C8.9463 5.09883 8.89925 6.07368 8.85219 7.04853L8.85219 7.04857C8.80513 8.02342 8.75808 8.99826 8.75808 9.7983C8.75808 10.2151 8.30091 10.3899 7.97821 10.3899C7.54795 10.3899 7.18486 10.2151 7.18482 9.7983C7.18482 8.99823 7.13777 8.02336 7.09071 7.0485C7.04366 6.07365 6.9966 5.09881 6.9966 4.29878ZM6.95312 11.6552C6.95312 11.0904 7.39691 10.6198 7.98852 10.6198C8.53978 10.6198 9.01047 11.0904 9.01047 11.6552C9.01047 12.2065 8.53982 12.6906 7.98852 12.6906C7.39686 12.6906 6.95312 12.2065 6.95312 11.6552Z"
              fill="#282828"
            />
          </svg>
        </div>
        <div class="flex">
          <button
            class="px-2 py-1.5 bg-brand rounded-lg text-white text-xs hover:bg-brand-hove"
            @click="$emit('createCase', packageDetail)"
          >
          <Loader :size="1" class="mr-2" v-if="createCaseLoading" />
            {{ buttonName }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const Loader = () => import("@/components/loader");

export default {
  name: "PackageCard",
  components: {
    Loader,
  },
  props: {
    packageName: {
      type: String,
      default: "",
      required: true,
    },
    buttonName: {
      type: String,
      default: "Start",
      required: true,
    },
    packageDetail: {
      required: true,
      type: Object,
      default: () => {},
    },
    createCaseLoading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    // getSla() {
    //   return this.packageDetail?.turn_around_time >= 1 ? this.packageDetail.turn_around_time + " days" : "No SLA set for this package";   
    // }
    getDescription(){
      return this.packageDetail?.package_description
    }
  }
};
</script>

<style lang="scss" scoped>
.package-card-container {
  width: 16rem;
  height: 3.5rem;
  padding: 1rem;
}
.package-name-container {
  height: 1.25rem;
  width: 8.5rem;
}
.package-name {
  font-family: Poppins;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
</style>
