import i18n from "@shared/plugins/i18n";
import { getPermissionErrorMessages } from "@shared/utils/permissions/constants";
import { getPermissionCode } from "@shared/utils/functions";

export class PermissionError extends Error {
  constructor(permission, message) {
    const permissionErrorMessages = getPermissionErrorMessages();

    const resultMessage = message ?? permissionErrorMessages[permission] ?? i18n('errors.permissions.default');

    super(resultMessage);

    this.name = "PermissionError";
    this.permission = permission;
    this.code = getPermissionCode(permission);
  }
}