<!-- Localized -->
<template>
    <div class="folders flex border-2 bg-white p-2 items-center justify-center rounded-xl shadow cursor-pointer hover:border-primary" @click="onClick()" :class="directory.id === selectedFolder ? 'border-primary' : 'border-transparent'">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M17.6337 15.69H4.6039C3.36047 15.69 2.11312 14.7531 1.76627 13.5584L0.0917267 7.79049C-0.291551 6.47028 0.565015 5.32869 1.93958 5.32869H2.2377V2.36641C2.2377 1.05942 3.29718 0 4.6043 0H9.34114C9.88091 0 10.4743 0.343092 10.7415 0.810563L11.3402 1.85835C11.396 1.95591 11.5967 2.07228 11.7083 2.07228H17.6297C18.9379 2.07228 20 3.1332 20 4.43918V13.3231C20 14.5273 19.1012 15.5215 17.9378 15.6707C17.8507 15.682 17.761 15.6885 17.6689 15.6898C17.6571 15.6899 17.6454 15.69 17.6337 15.69ZM3.42183 5.32869H14.9694C16.2129 5.32869 17.4602 6.26566 17.8071 7.46033L18.8158 10.935V4.43918C18.8158 3.78747 18.2842 3.25645 17.6297 3.25645H11.7083C11.172 3.25645 10.5788 2.91262 10.3121 2.44588L9.71332 1.39809C9.65738 1.3002 9.45668 1.18417 9.34114 1.18417H4.6043C3.95116 1.18417 3.42183 1.71343 3.42183 2.36641V5.32869Z"
                fill="#D37EE1"
            />
        </svg>
        <span class="ml-2 font-medium">{{ directory.name }}</span>
    </div>
</template>

<script>
export default {
    name: "folders",
    props: {
        directory: {
            type: Object,
            default: () => {},
        },
        selectedFolder: {
            type: [Number, String],
            default: null,
        },
    },
    methods: {
        onClick() {
            this.$emit("on-click", this.directory);
        },
    },
};
</script>

<style lang="scss" scoped></style>
