var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._g({staticClass:"plus-icon__wrapper flex items-center justify-center rounded-full cursor-pointer w-8 border-2 border-primary-focus h-8",class:{
        'bg-transparent': !_vm.item.selected && !_vm.item.warning && !_vm.item.error,
        'bg-error': _vm.item.error && !_vm.item.selected,
        'opacity-50 pointer-events-none cursor-not-allowed': _vm.item.disabled,
        'cursor-pointer': _vm.item.selectable
    },attrs:{"type":"primary","disabled":_vm.disabled}},_vm.$listeners),[_c('font-awesome-icon',{staticClass:"plus-icon__icon text-xl",class:{
            'text-primary': !_vm.item.selected && !_vm.item.warning && !_vm.item.error
        },attrs:{"icon":['fa', 'info']}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }