<!-- Localized -->
<template>
    <div
        class="dropdown h-7 2xl:h-10 rounded-lg 2xl:rounded-lg text-sm"
        :class="{
            'dropdown--menu': menu,
            'dropdown--searching': isSearchOffset,
        }"
        :style="{
            ...cssVars,
            width: width || 'auto',
            maxWidth: maxWidth || 'unset',
            minWidth: minWidth || 'unset',
        }"
        @click.stop.prevent
    >
        <VueMultiselect
            :options="options" 
            :multiple="multiple" 
            :searchable="searchable" 
            :close-on-select="!multiple" 
            :placeholder="placeholder" 
            v-model="calculatedValue" 
            @select="$emit('select', $event)" 
            @search-change="$emit('search', $event)" 
            @input="changeValue" 
            @open="opened" 
            @close="closed" 
            :allow-empty="allowEmpty" 
            :disabled="isDisabled" 
            :loading="loading" 
            v-bind="{ ...computedConfig, ...$attrs }" 
            ref="multiselect" 
            :class="{ 'open-top': openDirection === 'top' }">
            <template #caret="{ toggle }">
                <div class="caret caret--rotable right-2 2xl:right-1.5" :class="isDisabled ? 'tw-hidden' : ''" @mousedown.prevent.stop="toggle()">
                    <slot name="caret">
                        <dropdownIcon />
                    </slot>
                </div>
            </template>
            <template slot="singleLabel" slot-scope="props">
                <div class="custom-label custom-label--singleLabel">
                    <slot name="before" />
                    <span class="custom-label__text">
                        {{ getLabel(props.option) }}
                    </span>
                    <slot name="after" />
                </div>
            </template>
            <template slot="placeholder">
                <span class="custom-label custom-label--placeholder">
                    <slot name="before" />
                    <span class="custom-label__text" :class="{ opacity: !menu }">{{ placeholder }}</span>
                    <slot name="after" />
                </span>
            </template>
            <template slot="option" slot-scope="props">
                <slot name="option" :option="props.option">
                    <div class="custom-label custom-label--option p-3" :title="getLabel(props.option)">
                        <input v-if="multiple" type="checkbox" :checked="isOptionChecked(props.option)" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-md pointer-events-none" />
                        <component v-if="props.option.icon" :is="props.option.icon" class="custom-label__icon" />
                        <span class="custom-label__text" :style="props.option.icon ? '' : 'width: calc(100% - 26px)'">
                            {{ getLabel(props.option) }}
                        </span>
                    </div>
                </slot>
            </template>
            <template slot="tag" slot-scope="props">
                <div class="custom-tag h-5 text-xs 2xl:h-6 2xl:text-sm">
                    <span class="truncate custom-tag-label">
                        {{ getLabel(props.option) }}
                    </span>
                    <button class="custom-tag__icon" :title="$t('actions.remove')" type="button" @click.stop.prevent="props.remove(props.option)">
                        <cross-icon />
                    </button>
                </div>
            </template>
            <template v-if="!taggable && multiple && value && value.length" slot="selection">
                <slot name="selection">
                    <div class="custom-label custom-label--selection">
                        <span class="custom-label__text">
                            {{ $t('components.dropdown.n_units_selected', { count: value.length, units: units }) }}
                        </span>
                    </div>
                </slot>
            </template>
            <template v-if="multiple && options && options.length" slot="beforeList">
                <div class="custom-label multiselect__before-list flex items-center gap-1 py-2 px-3">
                    <span class="font-bold capitalize text-base">
                        {{ units }}
                    </span>
                    <div class="font-normal text-primary text-base" @click="toggleAll">
                        {{ value.length === options.length ? $t('components.multiselect.clear_all') : $t('components.multiselect.select_all') }}
                    </div>
                </div>
            </template>
            <template v-if="multiple && value && value.length" slot="clearAll" slot-scope="{ clearAll }">
                <div class="custom-label custom-label--clear-all py-2 px-3 block">
                    <div class="text-sm font-normal text-primary cursor-pointer" @click="clearAll">{{ $t('components.dropdown.clear_selected_items') }}</div>
                </div>
            </template>
            <template v-if="loading" slot="noResult">{{ $t('general.loading') }}</template>
        </VueMultiselect>
    </div>
</template>
<script>
// TODO v-model

import VueMultiselect from "@/components/vue-multiselect";
import dropdownIcon from "@/assets/icons/dashboard/dropdown.svg";
import crossIcon from "@/assets/icons/dashboard/cross.svg";

export default {
    name: "dropdown",
    components: {
        VueMultiselect,
        dropdownIcon,
        crossIcon,
    },
    props: {
        value: [Object, Array, String, Number],
        menu: {
            type: Boolean,
            default: false,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        config: {
            type: Object,
            default: () => ({}),
        },
        options: {
            type: Array,
            default: () => [],
        },
        colors: {
            type: Object,
            default: () => ({}),
        },
        optionIcons: {
            type: Object,
            default: () => ({}),
        },
        disabled: Boolean,
        placeholder: String,
        width: String,
        maxWidth: { type: String, default: () => "15rem" },
        minWidth: String,
        searchable: {
            type: Boolean,
            default: false,
        },
        reposition: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        openDirection: {
            type: String,
            default: "bottom",
            // values can be 'top' or 'bottom'
        },
        allowEmpty: {
            type: Boolean,
            default: true,
        },
        taggable: {
            type: Boolean,
            default: true,
        },
        units: {
            type: String,
            default: "options",
        },
    },
    data() {
        return {
            calculatedValue: null,
            isOpen: false,
        };
    },
    // created() {
    //   this.calculatedValue = this.initialValue;
    // },
    computed: {
        isDisabled() {
            // return !this.options.length;
            return this.disabled;
        },
        cssVars() {
            return {
                "--color-placeholder": this.colors.placeholder || "#000",
                "--color-text": this.colors.text || "#fff",
                "--color-bg": this.colors.bg || "#029ADB",
                "--color-border": this.colors.border || "#029ADB",
                "--color-svg": this.colors.svg || this.colors.text || "#fff",
            };
        },
        computedConfig() {
            return {
                searchable: false,
                limit: 2,
                selectLabel: "",
                deselectLabel: "",
                ...this.config,
            };
        },
        isSearchOffset() {
            return this.isOpen && this.searchable && this.calculatedValue?.length && this.multiple;
        },
    },
    created() {
        document.addEventListener("scroll", this.placeDropdown, true);
        window.addEventListener("resize", this.placeDropdown);
    },
    mounted() {
        this.$nextTick(() => {
            this.placeDropdown();
            this.calculatedValue = this.value;
        });
    },
    destroyed() {
        document.removeEventListener("scroll", this.placeDropdown, true);
        window.removeEventListener("resize", this.placeDropdown);
    },
    watch: {
        calculatedValue() {
            this.placeDropdown();
        },
        value(value) {
            this.calculatedValue = value;
            // this.changeValue(value);
        },
    },
    methods: {
        changeValue(calculatedValue) {
            if (this.menu) this.calculatedValue = null;
            this.$emit("input", calculatedValue);
        },
        toggleAll() {
            if (this.value?.length === this.options?.length) this.clearAll();
            else this.selectAll();
        },
        selectAll() {
            this.changeValue([...this.options]);
        },
        clearAll() {
            this.changeValue([]);
        },
        isOptionChecked(option) {
            if (!this.multiple || !this.value) return false;
            return this.value.some((el) => {
                return (!!this.config.trackBy && el[this.config.trackBy] === option[this.config.trackBy]) || el === option;
            });
        },
        getLabel(prop) {
            if (prop?.$groupLabel) {
                return prop.$groupLabel;
            }
            else if (typeof(prop) === 'object') {
                let label = prop[this.config.label || "title"]
                return label;
            } else return prop
        },
        closed() {
            this.isOpen = false;
        },
        opened() {
            this.isOpen = true;
            this.$nextTick(() => {
                const dropdownLists = document.querySelectorAll('.multiselect__content-wrapper');
                dropdownLists.forEach((dropdownList) => {
                    dropdownList.scrollTop = 0;
                });
            });
            this.placeDropdown();
        },
        placeDropdown() {
            if (this.reposition && this.isOpen) {
                this.$nextTick(() => {
                    const { top, height, width, left } = this.$el.getBoundingClientRect();
                    const ref = this.$refs.multiselect;
                    if (ref) {
                        const searchInput = ref.$el.querySelector(".multiselect__input");
                        ref.$refs.list.style.width = `${width}px`;
                        // ref.$refs.list.style.width = `fit-content`;
                        ref.$refs.list.style.minWidth = `${width}px`;
                        ref.$refs.list.style.position = "fixed";
                        ref.$refs.list.style.bottom = "auto";
                        const addOffset = this.isSearchOffset ? 34 : 0;
                        if (searchInput) {
                            if (addOffset) {
                                searchInput.style.width = "100%";
                                searchInput.classList.add("h-9");
                                searchInput.setAttribute("placeholder", this.placeholder);
                            } else {
                                searchInput.classList.remove("h-9");
                            }
                        }
                        ref.$refs.list.style.top = `${top + height + addOffset}px`;
                        ref.$refs.list.style.left = `${left}px`;
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/mixins/mixins.scss";
@import "@/assets/styles/functions.scss";

.dropdown {
    $placeholder: var(--color-placeholder);
    $text: var(--color-text);
    $bg: var(--color-bg);
    $border: var(--color-border);
    $svg: var(--color-svg);
    @include buttonBase($text: $text, $border: $border, $bg: $bg, $svg: $svg, $placeholder: $placeholder, $responsive: true);

    position: relative;
    display: flex;
    // align-items: stretch;
    min-width: 114px;
    padding: 0;
    cursor:pointer;
    &.cursor-auto {
        cursor: auto!important;
    }
    .caret {
        display: inline-flex;
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        // right: 14px;
        padding: 5px 1px;
        z-index: 1;
        &.tw-hidden {
            display: none;
        }
        svg {
            // width: 12px;
            transition: transform 0.2s;
            fill: $placeholder;
            // path {
            //   fill: $caret;
            // }
        }
    }
    ::v-deep {
        .multiselect {
            outline: none;
            width: 100%;
            display: flex;
            min-height: unset;
            font-size: toRem(14px);
            &--active {
                .caret {
                    &--rotable {
                        svg {
                            transform: rotate(-180deg);
                        }
                    }
                }
            }
            // custom label template:
            .custom-label {
                display: flex;
                align-items: center;
                gap: 10px;
                width: 100%;
                svg {
                    flex-shrink: 0;
                    @apply w-5 h-5;
                }
                &__text,
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    text-align: left;
                    color: $text; // TODO
                    white-space: nowrap;
                    &.opacity {
                        opacity: 0.8;
                    }
                }
                &__text {
                    flex-grow: 1;
                    &.opacity {
                        opacity: 0.8;
                    }
                }
                &--selection {
                    font-weight: initial;
                }
            }
        }
        .multiselect__content-wrapper {
            @include scrollBar;
            border-radius: 0 0 16px 16px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4);
            top: 102%;
            // max-width: 192px !important;
            // width: auto;
            // width: 190.8px !important;
            // min-width:102px !important;
            right: 0;
            height: min-content;
            // margin-left:0.55px !important;
            // min-width: 160px;
        }
        .open-top .multiselect__content-wrapper {
            border-radius: 16px 16px 0 0 !important;
            top: auto !important;
            bottom: 28px !important;
        }
        .multiselect__content {
            width: 100%;
        }
        .multiselect__tags {
            background: none;
            border: none;
            min-height: 24px;
            max-width:192px;
            width: 100%;
            box-sizing: border-box;
            padding: 0 32px 0 14px;
            display: inline-flex;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            &-wrap {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                @apply gap-1 2xl:gap-3;
                width: 100%;
                overflow-x: auto;
                @include scrollBar(8px, 4px);
                .custom-tag {
                    padding: 0 28px 0 12px;
                    border-radius: 8px;
                    display: inline-flex;
                    white-space: nowrap;
                    align-items: center;
                    // font-size: toRem(14px);
                    font-weight: 600;
                    position: relative;
                    background-color: #d9d9d9;
                    overflow: hidden;
                    // height: 24px;
                    &__icon {
                        position: absolute;
                        right: 7px;
                        transform: translateY(-50%);
                        top: 50%;
                        cursor: pointer;
                    }
					.custom-tag-label {
                        background-color: #d9d9d9;
                        @apply text-gray-800;
                    }
                }
            }
            .multiselect__strong {
                margin-bottom: 0;
            }
        }
        .multiselect__single {
            background: none;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .multiselect__single,
        .multiselect__placeholder,
        .multiselect__option {
            white-space: nowrap;
            overflow: hidden;
            display: flex;
            align-items: center;
            padding: 0;
        }
        .multiselect__single,
        .multiselect__placeholder {
            .custom-label {
                &__text {
                    color: $placeholder!important;
                    // color: $text;
                }
            }
        }
        .multiselect__option {
            .custom-label {
                &__text {
                    color: #282828;
                }
                svg {
                    fill: #282828;
                }
            }
        }
        .multiselect__select {
            z-index: 1;
        }
        .multiselect__element {
            &:not(:first-child) {
                .multiselect__option {
                    border-top: 1px solid #a8a8a8;
                }
            }
        }
        .multiselect__option {
            font-size: toRem(14px);
            min-height: toRem(18px);
            box-sizing: border-box;
            color: #282828;
            font-weight: 500;
            // .custom-label {
            //   svg {
            //       fill: #282828;
            //       path {
            //           fill: #282828;
            //       }
            //   }
            // }
            // &--selected {
            //   background-color: #cce3ff96!important;
            // }
            &--highlight {
                background-color: #cce3ff !important;
                // padding-right: toRem(16px);
                border-right: 4px solid #3b2cd4;
                .custom-label {
                    @apply pr-2;
                }
            }
        }
        .multiselect__placeholder {
            width: 100%;
            margin: 0;
            color: $placeholder;
            
            
        }
        .multiselect__input {
            margin-bottom: 0;
            padding: 0;
            z-index: 999;
        }
        .multiselect__spinner {
            &::before,
            &::after {
                border-color: #0d69d5 transparent transparent;
            }
        }
    }
    &--searching {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        ::v-deep {
            .multiselect__input {
                @apply px-3;
                top: 100%;
                left: 0;
                width: 100%;
                position: absolute;
                // box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4)!important;
                border-radius: 0;
            }
        }
    }
}
</style>
