<!-- Localized -->
<template>
    <div class="header">
        <div class="header__start flex justify-start">
            <!-- <Button
                v-if="!noBack"
                class="header__back-btn btn"
                text="Back"
                outline
                type="primary"
                @click="$emit('back')"
                icon="angle-left"
                :align-icon="false"
                icon-classes="text-sm"
            /> -->
        </div>
        <Timeline
            v-if="!!timeline?.list?.length"
            class="header__timeline"
            :list="timeline.list"
            :current="timeline.current"
            readonly
        />
        <div class="header__end flex justify-end">
            <Button
                v-if="!noCancel"
                class="btn text-red-500 hover:text-white-text border-red-500 hover:border-red-600 hover:bg-red-600"
                :text="$t('case_creation.cancel_case')"
                outline
                @click="$emit('cancel')"
            />
        </div>
    </div>
</template>

<script>
import Timeline from '@shared/components/timeline/index.vue';
import Button from "@shared/components/button";

export default {
    components: {
        Timeline,
        Button,
    },
    props: {
        /**
         * @typedef {Object} Timeline
         * @property {array} list
         * @property {object} [current]
         */
        /**
         * @type {Timeline}
         */
        timeline: {
            type: Object,
            default: null,
        },
        noBack: {
            type: Boolean,
            default: false,
        },
        noCancel: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/functions.scss';

.header {
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    justify-content: space-between;
    align-items: center;
    &__start {
        grid-column: 1;
    }
    &__timeline {
        grid-column: 2;
        justify-content: center;
    }
    &__end {
        grid-column: 3;
    }
    .btn {
        padding: 0 toRem(8px);
        display: inline-flex;
        align-items: center;
        height: toRem(28px);
        border-radius: toRem(6px);
        font-size: toRem(12px);
        border-width: toRem(1px);
    }

    &__back-btn:focus:not(:hover) {
        background: unset;
    }
}
</style>