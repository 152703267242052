<!-- Localized -->
<template>
    <UIContentBox class="flex items-center justify-center gap-5">
        <Button
            :loader="loading"
            type="primary"
            class="capitalize"
            :text="currentConfig.text"
            :disabled="disabled"
            @click="$emit(currentConfig.event)"
        />
    </UIContentBox>
</template>

<script>
import Button from "@shared/components/button";

export default {
    components: {
        Button,
    },
    props: {
        type: {
            type: String,
            default: 'next',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        config() {
            return {
                next: {
                    text: "Next",
                    event: "next",
                },
                submit: {
                    text: "Submit",
                    event: "submit",
                },
            }
        },
        currentConfig() {
            return this.config[this.type];
        },
    },
}
</script>

<style lang="scss" scoped>

</style>